import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  content: {
    [breakpoints.down("md")]: {
      padding: spacing(8, 4),
    },
    width: "100%",
    maxWidth: 750,
    margin: "0 auto",
    padding: spacing(4, 8),
    "& > h1": {
      maxWidth: 350,
      textAlign: "center",
      margin: "0 auto",
      paddingBottom: spacing(2),
      fontSize: "2.5em",
      color: palette.secondary.main,
    },
    "& > p": {
      fontSize: "1.1em",
    },
  },
}))

const AboutPage = () => {
  const classes = useStyles()

  const {
    markdownRemark: { html },
  } = useStaticQuery(graphql`
    query aboutQuery {
      markdownRemark(frontmatter: { id: { eq: "Over Mij" } }) {
        html
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Over mij" />
      <div
        dangerouslySetInnerHTML={{ __html: html }}
        className={classes.content}
      />
    </Layout>
  )
}

export default AboutPage
